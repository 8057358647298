import {
  CommentReactionId,
  CommentableEntity,
  HexId,
  ReviewRequestId,
  UserId,
  asciiCompare,
  stableEmptyArray,
} from "@hex/common";
import {
  EntityState,
  PayloadAction,
  createDraftSafeSelector,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { groupBy, memoize } from "lodash";

import { HexAppMpFragment } from "../../hex-multiplayer/HexAppMPModel.generated.js";
import {
  CommentMpFragment,
  HexMpFragment,
} from "../../hex-multiplayer/HexMPModel.generated";
import { RootState } from "../store.js";
import { getSelectorsForEntityStateWithSoftDelete } from "../utils/entityAdapterSelectorCreator.js";
import { assertNonNull } from "../utils/types.js";

//#region action helper types
type WithHexId<T> = {
  hexId: HexId;
  data: T;
};
export type HexAction<D, T extends string = string> = PayloadAction<
  WithHexId<D>,
  T
>;

type SetFieldAction<T> = HexAction<{
  key: keyof NonNullable<T>;
  value: unknown;
}>;
type SetEntityFieldPayload<T extends { id: string }> = {
  entityId: T["id"];
  key: keyof NonNullable<T>;
  value: unknown;
};
type SetEntityFieldAction<T extends { id: string }> = HexAction<
  SetEntityFieldPayload<T>
>;

//#region types of data in redux store
export type HexMP = Omit<HexMpFragment, "comments">;
export type CommentMP = Omit<CommentMpFragment, "childComments">;

export type CommentOrigin =
  | "all"
  | "project"
  | "app"
  | "review"
  | ReviewRequestId;

//#region adapters for normalization of lists of elements

const commentAdapter = createEntityAdapter<CommentMP>({
  selectId: (comment) => comment.id,
  // sort from newest to oldest
  sortComparer: (a, b) => asciiCompare(b.createdDate, a.createdDate),
});

//#region reducer for data for a single HexMP instance

// main shape of state
export type HexMPValue = {
  hex: HexMP;
  comments: EntityState<CommentMP>;
};

const hexMPValueSlice = createSlice({
  name: "hexMPValue",
  // This initial state is essentially meaningless,
  // Since this reducer is only called as a function directly by `hexMPReducer` and
  // we always should have a `initializeFromHexMPData` action to really initialize things.
  initialState: null as unknown as HexMPValue,
  reducers: {
    // hex actions
    setHexField(state, action: SetFieldAction<HexMP>) {
      const hex: HexMP = state.hex;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (hex as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    // comment actions
    setCommentField(
      state,
      { payload: { data } }: SetEntityFieldAction<CommentMP>,
    ) {
      commentAdapter.updateOne(state.comments, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertComment(state, action: HexAction<CommentMP>) {
      commentAdapter.upsertOne(state.comments, action.payload.data);
    },
    // comment reaction actions
    insertCommentReaction(
      state,
      {
        payload,
      }: HexAction<{
        commentId: string;
        reaction: CommentMP["reactions"][number];
      }>,
    ) {
      const { commentId, reaction } = payload.data;
      const comment = commentAdapter
        .getSelectors()
        .selectById(state.comments, commentId);
      if (comment == null) {
        return;
      }

      const { reactions } = comment;
      const reactionIndex = reactions.findIndex(
        (r) =>
          r.id === reaction.id ||
          (r.creatorId === reaction.creatorId &&
            r.content === reaction.content),
      );

      // if user has already created a new reaction and is trying to react
      // to the same comment with the same content, noop
      if (reactionIndex > -1) {
        return;
      }

      const newReactions = [...reactions, reaction];
      commentAdapter.upsertOne(state.comments, {
        ...comment,
        reactions: newReactions,
      });
    },
    deleteCommentReaction(
      state,
      {
        payload,
      }: HexAction<{
        commentId: string;
        reactionId: CommentReactionId;
        creatorId: UserId;
      }>,
    ) {
      const { commentId, creatorId, reactionId } = payload.data;
      const comment = commentAdapter
        .getSelectors()
        .selectById(state.comments, commentId);
      if (comment == null) {
        return;
      }

      const { reactions } = comment;
      const newReactions = reactions.filter(
        (r) => !(r.id === reactionId && r.creatorId === creatorId),
      );

      // if no reactions filtered, noop
      if (newReactions.length === reactions.length) {
        return;
      }

      commentAdapter.upsertOne(state.comments, {
        ...comment,
        reactions: newReactions,
      });
    },
    // main initialization actions
    initializeFromHexMPData(_state, action: HexAction<HexMpFragment>) {
      const { comments: rawComments, ...hex } = action.payload.data;

      const comments = rawComments.map(
        ({ childComments: ________, ...comment }) => comment,
      );

      return {
        hex,
        comments: commentAdapter.setAll(
          commentAdapter.getInitialState(),
          comments,
        ),
      };
    },
    initializeFromHexAppMPData(_state, action: HexAction<HexAppMpFragment>) {
      const { appComments: rawComments, ...hex } = action.payload.data;

      const comments = (rawComments ?? []).map(
        ({ childComments: ________, ...comment }) => comment,
      );

      return {
        hex,
        comments: commentAdapter.setAll(
          commentAdapter.getInitialState(),
          comments,
        ),
      };
    },
  },
});

//#region selectors
// We wrap each function that creates selectors in `memoize`. This has a few benefits:
//   - We don't have to construct a whole new object of selectors each call
//   - And more importantly, you get back the same selector instances each time you call it,
//     which means that the cache for reselect selectors will work globally and thus will only
//     have to run the computation one time (instead of once per component)
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */
export const hexMPSelectors = {
  getHexSelectors: memoize((hexId: HexId) => ({
    select: (state: RootState): HexMP => {
      const hex = state.hexMP[hexId]?.hex;
      assertNonNull(hex, `Can't select hex with id ${hexId}`);
      return hex;
    },
    safeSelect: (state: RootState): HexMP | null =>
      state.hexMP[hexId]?.hex ?? null,
  })),
  getCommentSelectors: memoize((hexId: HexId) => {
    type CommentWithChildren = CommentMP & { childComments: CommentMP[] };
    type EntityCommentMap = {
      [entityId: string]: readonly CommentWithChildren[];
    };

    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      commentAdapter,
      (state) => state.hexMP[hexId]?.comments,
    );

    const groupCommentsByEntityId = (
      comments: CommentMP[],
    ): EntityCommentMap => {
      const commentMap = groupBy(comments, (c) => c.entityId);
      const nonCommentEntityIds = new Set(
        comments.flatMap((c) =>
          c.entityType === CommentableEntity.COMMENT ? [] : [c.entityId],
        ),
      );
      const result: EntityCommentMap = {};
      for (const entityId of nonCommentEntityIds) {
        result[entityId] = (commentMap[entityId] ?? []).map((rootComment) => ({
          ...rootComment,
          childComments: commentMap[rootComment.id] ?? [],
        }));
      }
      return result;
    };

    const groupCommentsWithChildren = (
      comments: CommentMP[],
    ): CommentWithChildren[] => {
      const commentMap = groupBy(comments, (c) => c.entityId);
      return comments
        .filter((c) => c.entityType !== CommentableEntity.COMMENT)
        .map((c) => ({ ...c, childComments: commentMap[c.id] ?? [] }));
    };

    const selectAllProjectComments = createDraftSafeSelector(
      baseSelectors.selectAll,
      (comments) =>
        (comments ?? []).filter(
          (c) => !c.appComment && c.reviewRequestId == null,
        ),
    );

    const selectAllAppComments = createDraftSafeSelector(
      baseSelectors.selectAll,
      (comments) => (comments ?? []).filter((c) => c.appComment),
    );

    const selectAllReviewComments = createDraftSafeSelector(
      baseSelectors.selectAll,
      (comments) => (comments ?? []).filter((c) => c.reviewRequestId != null),
    );

    const selectAllSpecificReviewComments = createDraftSafeSelector(
      baseSelectors.selectAll,
      (_state: RootState, reviewRequestId: ReviewRequestId) => reviewRequestId,
      (comments, reviewRequestId) =>
        (comments ?? []).filter((c) => c.reviewRequestId === reviewRequestId),
    );

    const selectEntityIdToAllComments = createDraftSafeSelector(
      baseSelectors.selectAll,
      (comments) => groupCommentsByEntityId(comments ?? []),
    );

    const selectEntityIdToProjectComments = createDraftSafeSelector(
      selectAllProjectComments,
      (comments) => groupCommentsByEntityId(comments),
    );

    const selectEntityIdToAppComments = createDraftSafeSelector(
      selectAllAppComments,
      (comments) => groupCommentsByEntityId(comments),
    );

    const selectEntityIdToReviewComments = createDraftSafeSelector(
      selectAllReviewComments,
      (comments) => groupCommentsByEntityId(comments),
    );

    const selectEntityIdToSpecificReviewComments = createDraftSafeSelector(
      selectAllSpecificReviewComments,
      (comments) => groupCommentsByEntityId(comments),
    );

    const selectEntityIdToComments = (
      state: RootState,
      { commentOrigin }: { commentOrigin: CommentOrigin },
    ) => {
      if (commentOrigin === "project") {
        return selectEntityIdToProjectComments(state);
      } else if (commentOrigin === "app") {
        return selectEntityIdToAppComments(state);
      } else if (commentOrigin === "all") {
        return selectEntityIdToAllComments(state);
      } else if (commentOrigin === "review") {
        return selectEntityIdToReviewComments(state);
      } else {
        return selectEntityIdToSpecificReviewComments(state, commentOrigin);
      }
    };

    const selectAllCommentsWithChildren = createDraftSafeSelector(
      baseSelectors.selectAll,
      (comments) => groupCommentsWithChildren(comments ?? []),
    );

    const selectProjectCommentsWithChildren = createDraftSafeSelector(
      selectAllProjectComments,
      (comments) => groupCommentsWithChildren(comments),
    );

    const selectAppCommentsWithChildren = createDraftSafeSelector(
      selectAllAppComments,
      (comments) => groupCommentsWithChildren(comments),
    );

    const selectReviewCommentsWithChildren = createDraftSafeSelector(
      selectAllReviewComments,
      (comments) => groupCommentsWithChildren(comments),
    );

    const selectSpecificReviewCommentsWithChildren = createDraftSafeSelector(
      selectAllSpecificReviewComments,
      (comments) => groupCommentsWithChildren(comments),
    );

    const selectCommentsWithChildren = (
      state: RootState,
      { commentOrigin }: { commentOrigin: CommentOrigin },
    ) => {
      if (commentOrigin === "project") {
        return selectProjectCommentsWithChildren(state);
      } else if (commentOrigin === "app") {
        return selectAppCommentsWithChildren(state);
      } else if (commentOrigin === "all") {
        return selectAllCommentsWithChildren(state);
      } else if (commentOrigin === "review") {
        return selectReviewCommentsWithChildren(state);
      } else {
        return selectSpecificReviewCommentsWithChildren(state, commentOrigin);
      }
    };

    return {
      ...baseSelectors,
      selectCommentsWithChildren,
      selectEntityIdToComments,
      selectCommentsByEntityId: (
        state: RootState,
        {
          commentOrigin,
          entityId,
        }: { entityId: string; commentOrigin: CommentOrigin },
      ) => {
        const commentsMap = selectEntityIdToComments(state, { commentOrigin });
        return commentsMap[entityId] ?? stableEmptyArray();
      },
    };
  }),
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

//#region full multi-HexMP reducer
type HexMpState = {
  [hexId: string]: HexMPValue | undefined;
};

export const hexMPActions = {
  ...hexMPValueSlice.actions,
} as const;

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(hexMPActions).map((a) => a.type),
);

export const hexMPReducer = createReducer<HexMpState>({}, (builder) =>
  builder.addMatcher(
    (action): action is HexAction<unknown> => allActionTypes.has(action.type),
    (state, action) => {
      state[action.payload.hexId] = castDraft(
        hexMPValueSlice.reducer(state[action.payload.hexId], action),
      );
    },
  ),
);
